import {
  AuthorityTemplate,
  Click,
  LinkedInProfile,
  MessageTemplate,
  Offer,
  OfferSub,
  Pref,
  Session,
  User,
  Wrap
} from "@prisma/client"
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"

//#region - Miscellaneous

export type PromoClaim = {
  id: string
  code: string
  claimed: string // [ISO string] date user claimed this promotion
  expires: string // [ISO string] date promotion expires for user based on Promo data
}

export type PromoAction = 'buyboost' | null

export type Review = {
  title: string
  summary: string
  pros: string
  cons: string
}

export type ClickOffer = Click & {
  offer?: Offer
}

export interface CookieValue {
  value: any
  timestamp: string
}

export type CountResult = { count: bigint }

export type WrapIncludes = Wrap & {
  user?: UserIncludes
}
export type UserIncludes = User & {
  profile?: LinkedInProfile
  sessions?: Session[]
  pref?: Pref
}

export type OfferIncludes = Offer & {
  offersubs?: OfferSub[]
  learnMoreLink: string // attributed to warmer and buyer-offer
  warmlink: string // clickable only for warmer-offer
  click?: Click
}

export interface WarmLinkProps {
  user?: UserIncludes // A user may not be logged in
}

export type OfferVideo = {
  title?: string
  id: string
}

export type WrapLogEvent = "c" | "s" | "d"

//#endregion

//#region - Connections

export interface Connection {
  publicId: string
}

export interface ConnectionUpload {
  address: string
  birthDate: string
  countryCode: string
  firstName: string
  fsdProfileUrn: string
  headline: string
  industry: string
  languageCode: string
  lastName: string
  memberUrn: string
  profileImageURL: string
  publicId: string
  summary: string
  test: boolean
}

export interface ICP_WARMERS_RESULT {
  offer_id: string
  user_id: string
  user_pid: string
  targets: number
  total: number
}

export type MakeProfilesAndConnectionsResult = {
  profile_id: string
  profiles_added: number
  connections_added: number
  total_connections: number
  duration_ms: number
}

export type ReachResults =
  | {
      reach1: number
      reach2: number
      avg_reach: number
    }
  | undefined

//#endregion

//#region Messaging

export type MessageTemplateTOML = {
  id: string
  template: string
  active: boolean
  admin: boolean
}

export type AuthorityTemplateTOML = {
  id: string
  level: number
  sub: number
  template: string
}

export type AuthorityLevel = { prime: number; sub: number }

export interface APITemplates {
  messages: MessageTemplate[]
  authorities: AuthorityTemplate[]
  missions: string[]
}

export type MessageTemplateType = string | MultivariateArray | "random"

export type MultivariateArray = string[][] | []

export enum SendStatus {
  NEW = 0,
  SHUNT = 1,
  SUCCESS = 200,
  PROBLEM = 400,
  ERROR = 500
}

export interface SendMessageItem {
  n: number // number of this item sent to CE
  id: string // Click table id
  offerId: string // Offer Id
  template_id?: string // template used for message
  to: string // Recipient's LinkedIn profile URL
  body: string // Message body to send
  sent: string | undefined
  status: SendStatus
}

export interface SendMessageResult {
  id: string
  sent_at?: string
  status: SendStatus
}

export interface SendMessages {
  from: string
  size: number
  dailymax: number
  messages: SendMessageItem[]
}

/**
 * Return new batch of messages to send to warmer's Chrome extension.
 * @return {Promise<messages[]} - new batch of messages
 * @param uid
 * @param globaldays
 * @param limit
 * @param test
 * @param testContactProfileId
 */
export type TargetMessagingParams = {
  userId: string
  globalCooldownDays?: number // days to cooldown a recipient from all warmers
  take?: number
  test?: boolean
  testProfileId?: string
}

export interface TargetMessagingResult {
  offer_id: string
  warmer_pid: string
  contact_pid: string
  contact_url: string
  affiliate_url: string
  to_name: string
  from_name: string
  offer_title: string
  offer_company: string
  offer_desc: string
  offer_desc2: string
  offer_desc3: string
  offer_note: string
  offersub_random: boolean
  offersub_template_id: string
  offersub_mvtids: object
  offersub_authlevel: number
}

export type TargetMessagingResults = TargetMessagingResult[]

export interface TestTargetMessagingResult {
  from_name: string
  to_pid: string
  to_name: string
  to_url: string
  offer_title: string
  offer_desc: string
  offer_desc2: string
  offer_desc3: string
  offer_note: string
}

/**
 * Defines variables to be inserted into a message template to generate a message.
 * I've left this vanilla with all required keys so caller is forced to supply them all, and not to forget to include any.
 * If the caller does not want to supply a field, they must consciously pass an empty string.
 * These also define the actual name of the placeholders, e.g. @to, @authority, @title, etc. in templates.
 */
export interface MessageVars {
  to: string // @to - recipient name
  from: string // @from - sender name
  authority: string // @authority - message
  note: string // @note - optional personal note
  title: string // @title - offer title
  desc: string // @desc - offer description
  desc2: string // @desc2 - offer description
  desc3: string // @desc3 - offer description
  trailer: string // @trailer - final phrase, see makeTrailer()
  warmlink: string // @warmlink - affiliate link, see makeWarmlink()
}

//#endregion

//#region - Targeting

export interface ComputeTargetsParams {
  userId: string
  offerId: string
  force?: boolean
}

export interface ComputeTargetsResults {
  compute_results: {
    computed: boolean
    count: number
  }
}

export interface GetOfferTargetsDownloadParams {
  userId: string
  offerId: string
}

// Request from targeting data grid to fetch profiles
export interface GetOfferTargetsParams {
  userId?: string
  offerId: string
  offset: number
  take: number
  sortModel: GridSortModel
  filterModel: GridFilterModel
}

export type GetOfferTargetsResult = {
  rows: OfferTargets
  total: number
}

// Sent to targeting data grid
export interface OfferTarget {
  id: string
  o: string // offerId
  total_count: number
  f: string // first
  l: string // last
  i: string // image
  p: string // profile url
  cc: string // country code
  cn: string // country name
  tt: string // title
  c: string // company name
  t: boolean // target
  b: boolean // blocked
  a: boolean // approved
  s: boolean // sent
  u: boolean // unsubscribed
  clicked: boolean
  purchased: boolean
  cooldown: boolean
  member: boolean
  down1: boolean
  down2: boolean
}

export type OfferTargets = OfferTarget[]

// Update from targeting data grid (one or more targets)
export interface PutOfferTarget {
  offerId: string
  id: string // profile
  approved: boolean | null
  blocked: boolean | null
}

export interface UpdateOfferTargetsParams {
  queryParams: GetOfferTargetsParams
  updates: PutOfferTarget[]
}

//#endregion
